import React from "react"
import SEO from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Layout from '../../containers/layout/layout'
import Header from '../../containers/layout/header/header-one'
import PageHeader from '../../containers/portfolio-header/workflow-automation/page-header'
import Footer from '../../containers/layout/footer/footer-one'
import { Container, Row, Col } from '../../components/ui/wrapper'
import Heading from '../../components/ui/heading'
import Text from '../../components/ui/text'
import Icon from '../../components/ui/icon'
import { FaRegClock, FaDatabase } from "react-icons/fa"
import List, { ListItem } from '../../components/ui/list'
import PostNav from '../../components/post-nav/layout-one'
import CTA from '../../containers/global/cta-area/section-four'

import {
    OverviewArea,
    ProblemArea,
    ProblemBox,
    ProblemTextBox,
    NavigationArea,
    Navigtion
} from '../../templates/case-study-template/case-study-template.style'


const PortfolioWorkflowAutomation = ({ location, overviewDesc, problemSolStyles: { problemHeading, problemIcon } }) => {
    const workflowAutomationData = useStaticQuery(graphql`
        query WorkflowAutomationsQuery {
            portfolios(title: {eq: "Workflow Automation"}) {
                title
                prevBoxImage
                overview {
                    heading
                    short_desc
                    tags
                    client {
                        name
                        designation
                        company
                    }
                }
                problem_solution {
                    id
                    problem
                    solution
                    technology
                }
            }
        } 
    `);

    const pageData = workflowAutomationData.portfolios;
    const {prevBoxImage, overview, problem_solution } = pageData;

    return (<Layout location={location}>
        <SEO title="Workflow Automation" />
        <Header />
        <main className="site-wrapper-reveal">
            <PageHeader />
            {overview && (
                <OverviewArea>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Heading as="h3" mb="30px">Overview</Heading>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                {overview.short_desc && <Text {...overviewDesc}>{overview.short_desc}</Text>}
                            </Col>
                        </Row>
                    </Container>
                </OverviewArea>
            )}
            {problem_solution && (
                <ProblemArea>
                    <Container>
                        <Row>
                            <Col md={4}>
                                <Heading as="h4" mb="50px">Clients</Heading>
                            </Col>
                            <Col md={{ span: 7, offset: 1 }}>
                                <Heading as="h4" mb="50px">Project Description</Heading>
                            </Col>
                        </Row>
                        {problem_solution.map(item => (
                            <ProblemBox key={item.id}>
                                <Row>
                                    <Col md={4}>

                                        <ProblemTextBox>
                                            <Icon {...problemIcon} iconName="fal fa-long-arrow-right" />
                                            <Heading {...problemHeading}>{item.problem}</Heading>
                                        </ProblemTextBox>

                                    </Col>
                                    <Col md={{ span: 7, offset: 1 }}>
                                        <List>
                                            <ListItem>
                                                <ProblemTextBox>
                                                    <span className="icon"><FaRegClock /></span>
                                                    <Icon {...problemIcon} iconName="fal fa-check" />
                                                    <Text>{item.solution}</Text>
                                                </ProblemTextBox>
                                            </ListItem>
                                            <ListItem>
                                                <ProblemTextBox>
                                                    <span className="icon"><FaDatabase /></span>
                                                    <Icon {...problemIcon} iconName="fal fa-check" />
                                                    <Text>{item.technology}</Text>
                                                </ProblemTextBox>
                                            </ListItem>
                                        </List>
                                    </Col>
                                </Row>
                            </ProblemBox>
                        ))}
                    </Container>
                </ProblemArea>
            )}
            <NavigationArea>
                <Container>
                    <Row>
                        <Col md={12}>
                            <Navigtion>
                                <PostNav
                                    rel="prev"
                                    slug="portfolio-insurance-institutions"
                                    title="Insurance Institutions"
                                    image={prevBoxImage}
                                />
                            </Navigtion>
                        </Col>
                    </Row>
                </Container>
            </NavigationArea>

            <CTA />
        </main>
        <Footer />
    </Layout>)
}

PortfolioWorkflowAutomation.defaultProps = {
    overviewStyles: {
        overviewDesc: {
            as: 'h4',
            fontSize: '20px',
            lineHeight: 1.43,
            fontweight: 300,
            color: '#333',
        }
    },
    problemSolStyles: {
        problemHeading: {
            as: 'h2',
            position: 'relative',
            fontSize: '24px',
            fontweight: 500,
            lineHeight: 1.74,
            color: '#086AD8',
            mb: '20px'
        },
        problemIcon: {
            fontSize: '18px',
            fontweight: 400,
            pr: '15px',
            mt: '5px'
        }
    },
}

export default PortfolioWorkflowAutomation
